import { useContext, useEffect, useRef, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    Divider,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Slide,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { Container } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Controller, useForm } from "react-hook-form";
import { Edit, Logout, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";
import ActiveSessions from "./active-sessions";
import ProfilePicture from "./profile-picture";
import { queryClient } from "../../App";
import DeleteMyAccount from "./deleteAccount";

function Profile() {
    const navigate = useNavigate();
    const { auth, setAuth } = useContext(AuthContext);

    // USESTATE
    const [expanded, setExpanded] = useState(false);
    const [sessionExpanded, setSessionExpanded] = useState(true);
    const [edit, setEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false)
    const [openChangePhone, setOpenChangePhone] = useState(false);
    const [file, setFile] = useState("");

    // USEFORM HOOK
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        mode: "onChange"
    });

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // MUTATION & QUERY
    const { mutate, isLoading, data, error } = useMutation((formData) => {
        return axiosPrivate.put(`/profile`, formData);
    });

    const { isLoading: profileLoading, data: profile, isFetching, refetch } = useQuery(["profile"], () => {
        return axiosPrivate.get(`/profile`);
    },
        {
            refetchOnWindowFocus: false,
            enable: false
        }
    );

    const { mutate: mutateSignOut, isLoading: signOutIsLoading } = useMutation((refreshToken) => {
        return axiosPrivate.post(
            "/logout",
            {
                refresh_token: refreshToken
            },
            {
                withCredentials: true
            }
        );
    });

    // HELPERS
    const signOut = async () => {
        const refreshToken = auth.refresh_token;
        try {
            await mutateSignOut(refreshToken);
            setAuth({});
            navigate("/login");
        } catch (error) {
            console.error({
                error,
                msg: error?.response?.data?.error?.message,
                default: error?.message
            });
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    TransitionComponent: Slide,
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    };

    const onSubmit = (profileData) => {
        const dataToBeSent = file !== "" ? { ...profileData, profile_picture: file } : profileData;
        mutate(dataToBeSent);
    }
    const handleChange = () => (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };
    const handleSessionPanelChange = () => (event, isExpanded) => {
        setSessionExpanded(isExpanded ? true : false);
    };

    // USEEFFECT
    useEffect(() => {
        if (profile) {
            for (var key in profile?.data?.data) {
                if (profile?.data?.data.hasOwnProperty(key)) {
                    setValue(key, profile?.data?.data[key]);
                }
            }
        }
    }, [setValue, profile]);

    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully updated.`, {
                variant: "success",
            });
            refetch();
            setEdit(false);
            queryClient.clear();
        }
    }, [data, enqueueSnackbar, refetch]);

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    });
                });
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                    }
                );
            }
        }
    }, [enqueueSnackbar, error]);

    // RENDER
    return profileLoading || isFetching ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "100vh"
            }}
        >
            <CircularProgress
                size={40}
                sx={{
                    color: "secondary.main",
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        </Box>
    ) : (
        <Container
            component="main"
            disableGutters={true}
            maxWidth="xl"
            sx={{
                backgroundColor: "#fff",
                minHeight: "100vh",
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: { xs: "flex", md: "none" },
                            justifyContent: "flex-end",
                            alignItems: "center",
                            bgcolor: "whitesmoke",
                            py: 1,
                            mb: 3,
                        }}
                    >
                        {edit ?
                            <>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#fff",
                                        ":hover": {
                                            bgcolor: "#fff",
                                            borderColor: "black",
                                        },
                                        mr: 1,
                                        px: 2,
                                        width: 100,
                                        textTransform: "none",
                                        borderColor: "black",
                                        borderRadius: 6,
                                    }}
                                    onClick={() => setEdit(false)}
                                >
                                    <Typography sx={{ color: "black", fontSize: 14 }}>Cancel</Typography>
                                </Button>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#1c2526",
                                        ":hover": { bgcolor: "#1c2526" },
                                        px: 2,
                                        width: 100,
                                        textTransform: "none",
                                        borderRadius: 6,
                                    }}
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={isLoading}
                                >
                                    <Typography sx={{ color: "#fff", fontSize: 14 }}>
                                        Update
                                    </Typography>
                                    {isLoading && (
                                        <CircularProgress
                                            size={20}
                                            sx={{
                                                color: "secondary.main",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </>
                            :
                            <>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#fff",
                                        ":hover": {
                                            bgcolor: "#fff",
                                            borderColor: "black",
                                        },
                                        color: "black",
                                        borderColor: "black",
                                        borderRadius: 6,
                                        mr: 1,
                                        px: 2,
                                        width: 100,
                                        textTransform: "none",
                                    }}
                                    onClick={() => setEdit(true)}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            mr: 1,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Edit
                                    </Typography>
                                </Button>
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "#888888",
                                        ":hover": {
                                            bgcolor: "#888888",
                                        },
                                        borderRadius: 6,
                                        px: 2,
                                        width: 100,
                                        textTransform: "none",
                                    }}
                                    onClick={signOut}
                                    disabled={signOutIsLoading}
                                >
                                    <Typography
                                        sx={{
                                            color: "#fff",
                                            fontSize: 14,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Signout
                                    </Typography>
                                    {signOutIsLoading && (
                                        <CircularProgress
                                            size={20}
                                            sx={{
                                                color: "secondary.main",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </>
                        }
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            py: { xs: 0, md: 4 },
                            px: { xs: 0, md: 2 },
                        }}
                    >
                        <ProfilePicture
                            edit={edit}
                            isOpen={open}
                            setIsOpen={setOpen}
                            refetch={refetch}
                            imageSrc={edit ? (file === "" ? profile?.data?.data?.profile_picture : file) : profile?.data?.data?.profile_picture}
                            setFile={setFile}
                        />
                        <Typography sx={{ fontWeight: "bold", mt: 1 }}>{profile?.data?.data?.first_name}</Typography>
                        {edit ? (
                            <Container maxWidth="xs">
                                <Box
                                    component="form"
                                    sx={{ mt: 1, overflow: 'auto' }}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: edit ? 0 : 0.5, py: edit ? 0 : 1 }}>
                                        <FormLabel>First Name:</FormLabel>
                                        <Controller
                                            name="first_name"
                                            control={control}
                                            rules={{
                                                required: "First Name is required",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    autoComplete="first_name"
                                                    error={!!errors?.first_name}
                                                    helperText={
                                                        errors?.first_name ? errors.first_name.message : null
                                                    }
                                                    size="small"
                                                    {...field}
                                                    sx={{
                                                        ml: 2,
                                                        my: 1,
                                                        width: "74.5%",
                                                        height: "50%"
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 0, py: 0 }}>
                                        <FormLabel>Middle Name:</FormLabel>
                                        <Controller
                                            name="middle_name"
                                            control={control}
                                            rules={{
                                                required: "Middle Name is required",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="middle_name"
                                                    error={!!errors?.middle_name}
                                                    helperText={
                                                        errors?.middle_name ? errors.middle_name.message : null
                                                    }
                                                    size="small"
                                                    {...field}
                                                    sx={{
                                                        ml: 2,
                                                        my: 1,
                                                        width: "70.5%"
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 0, py: 0 }}>
                                        <FormLabel>Last Name:</FormLabel>
                                        <Controller
                                            name="last_name"
                                            control={control}
                                            rules={{
                                                required: "Last Name is required",
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="last_name"
                                                    error={!!errors?.last_name}
                                                    helperText={errors?.last_name ? errors.last_name.message : null}
                                                    size="small"
                                                    {...field}
                                                    sx={{
                                                        ml: 2,
                                                        my: 1,
                                                        width: "74.8%"
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 0, py: 0 }}>
                                        <FormLabel>Gender:</FormLabel>
                                        <Controller
                                            name="gender"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="gender"
                                                    error={!!errors?.gender}
                                                    helperText={errors?.gender ? errors.gender.message : null}
                                                    size="small"
                                                    select
                                                    {...field}
                                                    sx={{
                                                        ml: 2,
                                                        my: 1,
                                                        width: "80.8%"
                                                    }}
                                                >
                                                    <MenuItem value="Male">
                                                        {"Male"}
                                                    </MenuItem>
                                                    <MenuItem value="Female">
                                                        {"Female"}
                                                    </MenuItem>
                                                </TextField>
                                            )}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 0, py: 0 }}>
                                        <FormLabel>Phone:</FormLabel>
                                        <Controller
                                            name="phone"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    disabled
                                                    variant="outlined"
                                                    autoComplete="phone-number"
                                                    error={!!errors?.phone}
                                                    helperText={errors?.phone ? errors.phone.message : null}
                                                    size="small"
                                                    {...field}
                                                    sx={{
                                                        ml: 2,
                                                        my: 1,
                                                        width: "85%"
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", my: 0, py: 0 }}>
                                        <FormLabel>Email:</FormLabel>
                                        <Controller
                                            name="email"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    variant="outlined"
                                                    autoComplete="email"
                                                    error={!!errors?.email}
                                                    helperText={errors?.email ? errors.email.message : null}
                                                    size="small"
                                                    {...field}
                                                    sx={{
                                                        ml: 2,
                                                        my: 1,
                                                        width: "100%"
                                                    }}
                                                    disabled={!!(profile?.data?.data?.email)}
                                                />
                                            )}
                                        />
                                    </Box>
                                    <Divider />
                                    <Box
                                        sx={{
                                            mt: 1,
                                            display: { md: "flex", xs: "none" },
                                            flexDirection: { xs: "column", md: "row" },
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            sx={{
                                                bgcolor: "#1c2526",
                                                ":hover": { bgcolor: "#fdbc3d" },
                                                mr: { xs: 0, md: 2 },
                                                py: 1,
                                                width: {
                                                    xs: "100%",
                                                    md: "40%"
                                                }
                                            }}
                                            onClick={() => setEdit(false)}
                                        >
                                            <Typography sx={{ color: "#fff", fontSize: 14 }}>Cancel</Typography>
                                        </Button>
                                        <Button
                                            size="small"
                                            sx={{
                                                bgcolor: "#fdbc3d",
                                                ":hover": { bgcolor: "#1c2526" },
                                                ml: { xs: 0, md: 2 },
                                                mt: { xs: 1, md: 0 },
                                                py: 1,
                                                width: {
                                                    xs: "100%",
                                                    md: "35%"
                                                }
                                            }}
                                            type="submit"
                                            disabled={isLoading}
                                        >
                                            <Typography sx={{ color: "#fff", fontSize: 14 }}>
                                                Update
                                            </Typography>
                                            {isLoading && data && (
                                                <CircularProgress
                                                    size={20}
                                                    sx={{
                                                        color: "secondary.main",
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </Box>
                                </Box>
                            </Container>
                        ) : (
                            <Container maxWidth="xs">
                                <Box
                                    component="div"
                                    sx={{ mt: 1, overflow: 'auto' }}
                                >
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 0.5, py: 1 }}>
                                        <FormLabel>First Name:</FormLabel>
                                        <Typography sx={{ fontWeight: 'medium', textAlign: 'center', ml: 2, py: 0.5 }}>
                                            {profile?.data?.data?.first_name}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 1, py: 1 }}>
                                        <FormLabel>Middle Name:</FormLabel>
                                        <Typography sx={{ fontWeight: 'medium', textAlign: 'center', ml: 2 }}>
                                            {profile?.data?.data?.middle_name}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 1, py: 1 }}>
                                        <FormLabel>Last Name:</FormLabel>
                                        <Typography sx={{ fontWeight: 'medium', textAlign: 'center', ml: 2 }}>
                                            {profile?.data?.data?.last_name}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 1, py: 1 }}>
                                        <FormLabel>Gender:</FormLabel>
                                        <Typography sx={{ fontWeight: 'medium', textAlign: 'center', ml: 2 }}>
                                            {profile?.data?.data?.gender}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 1, py: 1 }}>
                                        <>
                                            <FormLabel>Phone:</FormLabel>
                                            <Grid container sx={{ justifyContent: "flex-start" }}>
                                                <Box
                                                >
                                                    <IconButton
                                                        onClick={() => setOpenChangePhone(true)}
                                                    >
                                                        <Tooltip title="Change your phone">
                                                            <Edit sx={{ color: "primary.main" }} />
                                                        </Tooltip>
                                                    </IconButton>
                                                    <ChangePhone
                                                        open={openChangePhone && (profile?.data?.data?.phone !== undefined)}
                                                        phone={profile?.data?.data?.phone}
                                                        refetch={refetch}
                                                        setOpen={setOpenChangePhone}
                                                    />
                                                </Box>
                                            </Grid>
                                        </>
                                        <Typography sx={{ fontWeight: 'medium', textAlign: 'center', ml: 2 }}>
                                            {profile?.data?.data?.phone}
                                        </Typography>
                                    </Box>
                                    <Divider />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center", my: 1, py: 1 }}>
                                        <FormLabel>Email:</FormLabel>
                                        <Typography sx={{ fontWeight: 'medium', textAlign: 'center', ml: 2 }}>
                                            {profile?.data?.data?.email}
                                        </Typography>
                                    </Box>
                                    <Divider />

                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box
                                            sx={{
                                                my: 2,
                                                display: { md: "flex", xs: "none" },
                                                flexDirection: { xs: "column", md: "row" },
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                size="small"
                                                sx={{
                                                    bgcolor: "#1c2526",
                                                    ":hover": {
                                                        bgcolor: "#fdbc3d"
                                                    },
                                                    mr: { xs: 0, md: 2 },
                                                    py: 1,
                                                    width: {
                                                        xs: "100%",
                                                        md: "40%"
                                                    }
                                                }}
                                                startIcon={<Logout fontSize="small" sx={{ color: "primary.paper", mx: 0.5 }} />}
                                                onClick={signOut}
                                                disabled={signOutIsLoading}
                                            >
                                                <Typography sx={{ color: "primary.paper", fontSize: 14, mr: 1 }}>
                                                    Sign Out
                                                </Typography>
                                                {signOutIsLoading && (
                                                    <CircularProgress
                                                        size={20}
                                                        sx={{
                                                            color: "secondary.main",
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                            <Button
                                                size="small"
                                                sx={{
                                                    bgcolor: "#fdbc3d",
                                                    ":hover": {
                                                        bgcolor: "#1c2526"
                                                    },
                                                    ml: { xs: 0, md: 2 },
                                                    mt: { xs: 1, md: 0 },
                                                    py: 1,
                                                    width: {
                                                        xs: "100%",
                                                        md: "35%"
                                                    }
                                                }}
                                                startIcon={<Edit fontSize="small" sx={{ color: "primary.paper", mx: 0.5 }} />}
                                                onClick={() => setEdit(true)}
                                            >
                                                <Typography sx={{ color: "primary.paper", fontSize: 14, mr: 1 }}>
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <Box>
                                            <Button
                                                fullWidth
                                                size="small"
                                                sx={{
                                                    bgcolor: "#e4e4e4",
                                                    ":hover": { bgcolor: "#e4e4e4" },
                                                    textTransform: "none",
                                                    py: 1
                                                }}
                                                onClick={() => setOpenDeleteAccount(true)}
                                            >
                                                <Typography sx={{ color: "black", fontSize: 14, fontWeight: "bold" }}>
                                                    Delete My Account
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Container>
                        )}
                    </Box >
                </Grid>
                <Grid item xs={12} md={6}>
                    <Container maxWidth="xs">
                        <Box
                            sx={{
                                flex: 1,
                                py: { md: 4, xs: 1 },
                                mt: { md: 10, xs: 1 },
                                alignItems: "center",
                            }}
                        >
                            <Box>
                                <Typography sx={{ fontWeight: "bold", fontSize: 20, color: 'primary.main', mb: 1 }} >Security</Typography>
                                <Accordion expanded={expanded} onChange={handleChange()}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'row-reverse'
                                        }}
                                    >
                                        <Typography sx={{ flexShrink: 0, textAlign: 'center', fontWeight: "bold" }} type="password">
                                            Password
                                        </Typography>
                                        <Typography sx={{ flexShrink: 0, textAlign: 'center' }} type="password">
                                            {profile?.data?.data?.password}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid
                                            container
                                            sx={{
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            <TextField
                                                margin="normal"
                                                size="small"
                                                variant="outlined"
                                                type="password"
                                                value={"test"}
                                                disabled
                                                sx={{ width: { xs: "60%" } }}
                                            />
                                            <Button onClick={() => setOpenChangePassword(true)}>
                                                <Tooltip title="Change your password">
                                                    <Edit />
                                                </Tooltip>
                                            </Button>
                                            <ChangePassword
                                                open={openChangePassword}
                                                refetch={refetch}
                                                setOpen={setOpenChangePassword}
                                            />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <Accordion expanded={sessionExpanded} onChange={handleSessionPanelChange()}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            flexDirection: 'row-reverse',
                                        }}
                                    >
                                        <Typography sx={{ flexShrink: 0, textAlign: 'center', fontWeight: "bold" }} type="password">
                                            Active Sessions
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{
                                        mt: -4
                                    }}>
                                        <ActiveSessions />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
            <DeleteMyAccount
                signOut={signOut}
                openDeleteAccount={openDeleteAccount}
                setOpenDeleteAccount={setOpenDeleteAccount}
            />
        </Container >
    )
}

// Change Password Component
function ChangePassword(props) {
    // USESTATE & USEREF
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState("password");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [confirmInputType, setConfirmInputType] = useState("password");

    const password = useRef({});

    // USEFORM HOOK
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm({
        mode: "onChange"
    });

    password.current = watch("new_password", "");

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    const { mutate, isLoading, data, error } = useMutation((password) => {
        return axiosPrivate.patch(`/profile/password`, password);
    });

    // HELPERS
    const onSubmit = (formData) => {
        mutate(formData);
    }
    const handleClick = () => {
        props.setOpen(false);
    }

    // USEFFECT
    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully changed your password.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            props.setOpen(false);
            props.refetch();
        }
    }, [data, enqueueSnackbar, props]);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error]);

    // RENDER
    return (
        <Dialog open={props.open}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        p: "5%",
                    }}
                >
                    <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                        Change Your Password
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                        <Divider />
                        <Controller
                            name="old_password"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    id="old-password-input"
                                    sx={{
                                        mt: 1.5,
                                        mb: 1
                                    }}
                                    label="Old Password"
                                    fullWidth
                                    type={inputType}
                                    autoComplete="old_password"
                                    error={!!errors?.old_password}
                                    helperText={errors?.old_password ? errors.old_password.message : null}
                                    size="small"
                                    {...field}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setShowNewPassword(showNewPassword === false ? true : false);
                                                        setInputType(showNewPassword === false ? "text" : "password");
                                                    }}
                                                >
                                                    {showNewPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="new_password"
                            control={control}
                            rules={{
                                required: "Password is required",
                                minLength: {
                                    value: 6,
                                    message: "At least 6 characters is required."
                                },
                                maxLength: {
                                    value: 32,
                                    message: "The maximum characters allowed is 32."
                                }
                            }}
                            render={({ field }) => (
                                <TextField
                                    id="new-password-input"
                                    sx={{
                                        mt: 0.5,
                                        mb: 1
                                    }}
                                    label="New Password"
                                    fullWidth
                                    type={inputType}
                                    autoComplete="new-password"
                                    error={!!errors?.new_password}
                                    helperText={errors?.new_password ? errors.new_password.message : null}
                                    size="small"
                                    {...field}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setShowPassword(showPassword === false ? true : false);
                                                        setInputType(showPassword === false ? "text" : "password");
                                                    }}
                                                >
                                                    {showPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="confirm_password"
                            control={control}
                            rules={{
                                validate: value =>
                                    value === password.current || "The passwords do not match"
                            }}
                            render={({ field }) => (
                                <TextField
                                    id="confirm-password-input"
                                    sx={{
                                        mt: 0.5,
                                        mb: 1.5
                                    }}
                                    label="Confirm Password"
                                    fullWidth
                                    type={confirmInputType}
                                    autoComplete="confirm-current-password"
                                    error={!!errors?.confirm_password}
                                    helperText={
                                        errors?.confirm_password
                                            ? errors.confirm_password.message
                                            : null
                                    }
                                    size="small"
                                    {...field}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setShowConfirmPassword(showConfirmPassword === false ? true : false);
                                                        setConfirmInputType(showConfirmPassword === false ? "text" : "password");
                                                    }}
                                                >
                                                    {showConfirmPassword ? <Visibility sx={{ fontSize: "inherit" }} /> : <VisibilityOff sx={{ fontSize: "inherit" }} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Divider />
                        <Box
                            sx={{
                                my: 1,
                                display: 'flex',
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    ":hover": { bgcolor: "#FFAA00" },
                                    backgroundColor: "#1c2526",
                                    color: "#fff",
                                }}
                                onClick={handleClick}
                            >
                                Back
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                type="submit"
                                sx={{
                                    ":hover": { bgcolor: "secondary.main" },
                                    backgroundColor: "primary.main",
                                    color: "#fff",
                                }}
                                disabled={isLoading}
                            >
                                Submit
                                {isLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "primary.paper",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
}

// Change Phone Component
function ChangePhone(props) {
    // USESTATE & USEREF
    const [openOtp, setOpenOtp] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const phoneRef = useRef();

    // USEFORM HOOK
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm({
        mode: "onChange"
    });

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate();

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar();

    // QUERY & MUTATION
    const { isLoading: otpLoading, data: otpData, error: otpError, refetch } = useQuery(["otp"], () => {
        return (
            axios.get(
                '/otp',
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    params: {
                        type: "signup",
                        phone: phoneRef.current
                    }
                })
        )
    },
        {
            refetchOnWindowFocus: false,
            enabled: false,
            retry: false
        }
    );
    const { mutate, isLoading, data, error } = useMutation((phone) => {
        return axiosPrivate.patch(`/profile/phone`, phone);
    });

    // HELPERS
    const getOtp = (phoneData) => {
        phoneRef.current = phoneData.phone;
        refetch();
        setOpenOtp(true);
    }
    const onSubmit = (formData) => {
        setOpenOtp(false);
        mutate(formData);
    }
    const handleClick = () => {
        props.setOpen(false);
    }

    // USEFFECT// USEEFFECT
    useEffect(() => {
        if (props.phone) {
            setValue("phone", props.phone);
        }
    }, [setValue, props]);

    useEffect(() => {
        if (otpData && otpSent) {
            enqueueSnackbar(`Code sent to your phone!`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            setOtpSent(false);
        }
    }, [enqueueSnackbar, otpData, data, otpSent]);

    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Succesfully changed your phone.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000
            });
            setOpenOtp(false);
            props.setOpen(false);
            props.refetch();
        }
    }, [data, enqueueSnackbar, props]);

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) => {
                    return enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000
                    });
                });
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                    {
                        variant: "error",
                    }
                );
            }
        }
        if (otpError) {
            enqueueSnackbar(
                otpError?.response?.data?.error?.message ||
                error?.message ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000
                }
            );
        }
    }, [enqueueSnackbar, error, otpError]);

    // RENDER
    return (
        <Dialog open={props.open}>
            {!(openOtp && !otpLoading && !otpError) ?
                (<Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            p: "5%"
                        }}
                    >
                        <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
                            Change Your Phone
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit(getOtp)} sx={{ mt: 1 }}>
                            <Divider />
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: "Phone is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{
                                            mt: 1.5,
                                            mb: 1
                                        }}
                                        variant="outlined"
                                        label="Phone"
                                        fullWidth
                                        autoComplete="phone"
                                        error={!!errors?.phone}
                                        helperText={errors?.phone ? errors.phone.message : null}
                                        size="small"
                                        {...field}
                                    />
                                )}
                            />
                            <Divider />
                            <Box
                                sx={{
                                    my: 1,
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{
                                        ":hover": { bgcolor: "#FFAA00" },
                                        backgroundColor: "#1c2526",
                                        color: "#fff",
                                    }}
                                    onClick={handleClick}
                                >
                                    Back
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        ":hover": { bgcolor: "secondary.main" },
                                        backgroundColor: "primary.main",
                                        color: "#fff",
                                    }}
                                    disabled={otpLoading && openOtp}
                                >
                                    Next
                                    {otpLoading && openOtp && otpSent && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "primary.main",
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                ) : (
                    <Container component="main" maxWidth="xs">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                p: 2,
                            }}
                        >
                            <Typography sx={{ fontWeight: "bold", fontSize: 20, textAlign: 'center' }}>
                                Enter a code
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                                <Divider />
                                <Typography sx={{ fontWeight: "medium", fontSize: 16, pt: 1 }}>
                                    Please fill the code sent to your phone number: {phoneRef.current} via SMS.
                                </Typography>
                                <Controller
                                    name="otp"
                                    control={control}
                                    rules={{
                                        required: "Code is required"
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            sx={{
                                                mt: 1.5,
                                                mb: 1
                                            }}
                                            fullWidth
                                            size="small"
                                            label="Code"
                                            autoComplete="otp"
                                            autoFocus
                                            helperText={errors?.otp ? errors?.otp?.message : ""}
                                            error={errors?.otp}
                                            {...field}
                                        />
                                    )}
                                />
                                <Divider />
                                <Box
                                    sx={{
                                        mt: 1,
                                        display: 'flex',
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ":hover": { bgcolor: "#FFAA00" },
                                            backgroundColor: "#1c2526",
                                            color: "#fff",
                                        }}
                                        onClick={handleClick}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            ":hover": { bgcolor: "#1c2526" },
                                            backgroundColor: "#FFAA00",
                                            color: "#fff",
                                        }}
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        Submit
                                        {isLoading && (
                                            <CircularProgress
                                                size={24}
                                                sx={{
                                                    color: "secondary.main",
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                )}
        </Dialog>
    );
}
export default Profile;